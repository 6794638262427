import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SharingDataService } from "src/app/shared/service/sharing-data.service";
import { LoadingSpinnerService } from "src/app/shared/service/loading-spinner.service";
import { Subscription } from "rxjs";
import { TranslationService } from "src/app/shared/service/translation.service";
import { DataServiceServiceProfile } from "../services/profile-data-service.service";

@Component({
  selector: "app-alias-list",
  templateUrl: "./alias-list.component.html",
  styleUrls: ["./alias-list.component.scss"],
})
export class AliasListComponent implements OnInit, OnDestroy {

  @Input()
  isSwitchProfile: boolean = false;

  @ViewChild("showModalContent") showModalContent: TemplateRef<any> | undefined;
  allSupportedLangCodes: any[];
  constructor(
    private SharingData: SharingDataService,
    private routes: Router,
    private dataService: DataServiceServiceProfile,
    private loadingSpinnerService: LoadingSpinnerService,
    private route: ActivatedRoute,
    private translationService: TranslationService
  ) {
    this.aliasRoute = this.route.snapshot.paramMap.get("profile");
    this.allSupportedLangCodes = this.translationService.getSupportedLanguageByCode();
  }

  aliasRoute: any;
  aliasClicked: boolean = false;
  alias: any;
  username: string = "";
  aliasList: any;
  groupList: any;
  subscriptions: Subscription[] = [];
  isProfileSelected: boolean = false;
  profileNeedToSwitch: string = "";
  isSwitched: boolean = false;
  groupName: any;
  isProfileSwitched: boolean = false;
  payload: any;
  isCloseModal: boolean = false;
  showModal: boolean = true;
  component: string = "aliasComponent";
  isHeaderVisible: boolean = false;
  userEmail:string='';
  noAccess:boolean=false;
  translationData:any;
  isPageAvailable = true;
  subscribe: any;
  type:string='small-popup';
  isProfileChanged:boolean=false
  noGroupsAvailable:boolean=false
  aliasIds:string=''
  profileSelectedIndex:any;
  profileData:any;
  userData:any

  ngOnInit(): any {  
    if(!this.isSwitchProfile) {
      this.subscriptions.push(this.route.params.subscribe((params) => {
        this.isValidLanguage(params.languageId);
      }));
    }
     
  
    if (localStorage.getItem("profile")) {
      this.SharingData.setAliasList(true);
    }
    this.fetchData();
    this.translationService
      .getTranslationDataAsObservable()
      .subscribe((translationData: any) => {
        this.translationData = translationData;
      });
  }
  fetchData() {
    this.subscriptions.push(this.dataService
      .getAliasList()
      .subscribe({
        next: (response: any) => {
          let data = response['body']
        if (data) {
          this.userData=data
          this.username = data.displayName;
          this.userEmail=data.adminEmail
          localStorage.setItem('userName',this.username)
          localStorage.setItem('userEmail',this.userEmail)
          this.aliasList = data.profileInfo;
          this.aliasIds=data.profileIds  
        }
      },
        error: () => {
        }
  }));
  }

  isValidLanguage(languageId: string) {
    if (languageId && this.allSupportedLangCodes.includes(languageId)) {
      this.isPageAvailable = true;
    } else {
      this.routes.navigate(['404']);
    }
  }

  aliasSelected(i: any) {
    this.profileSelectedIndex=i
    this.isProfileChanged=localStorage.getItem("isProfileChanged")?true:false
    if (this.isProfileChanged) {
      //payload while switching profile
      this.profileNeedToSwitch = this.aliasIds[i]
      this.isSwitched = true;
      this.payload = undefined;
    } else {
      //initial payload
      this.groupName = {
        groupName: this.aliasIds[i]
      };
      this.payload = this.groupName;
    }
   this.loadingSpinnerService.show()
      this.dataService
      .switchProfile(this.payload, this.isSwitched, this.profileNeedToSwitch)
      .subscribe({
        next: (data) => {
          this.showModal = false;
          this.alias = true;
        if (data) {
          localStorage.setItem("isProfileSwitched", "true");
          this.routes.navigate(["/events"]);
          localStorage.setItem("profile", this.alias);
          localStorage.setItem(
            "aliasGroupName",
            this.aliasIds[i]
          )
          localStorage.setItem('profileName',this.aliasList[i].profileName)
          localStorage.setItem('profileImage',this.aliasList[i].profileImageUrl)
          this.isProfileSelected = true;
          this.SharingData.setAliasList(true);
          this.isProfileSwitched = true;
          this.SharingData.setProfileSelected(true)
          this.SharingData.setProfileChanged(true)
          this.toggleMobileMenu();
        }

      },
      error: (error)=>{
        if(error=='User already logged in a session'){
        this.callSwitchProfile()
        }
        this.loadingSpinnerService.hide()
      }
      
  });   
  }

  callSwitchProfile(){
    this.profileNeedToSwitch = this.aliasIds[this.profileSelectedIndex]
    this.dataService.switchProfile(null,true,this.profileNeedToSwitch).subscribe(()=>{
      localStorage.setItem('aliasGroupName',this.aliasIds[this.profileSelectedIndex])
      localStorage.setItem('profileName',this.aliasList[this.profileSelectedIndex].profileName)
      localStorage.setItem('profileImage',this.aliasList[this.profileSelectedIndex].profileImageUrl)
      localStorage.setItem("profile", this.alias);
      this.routes.navigate(['events']);
    })
  }

  toggleMobileMenu() {
    this.SharingData.setMobileMenuStatus(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
