import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingSpinnerService } from "src/app/shared/service/loading-spinner.service";
import { DataServiceServiceProfile } from '../services/profile-data-service.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { AppConstants } from 'src/app/shared/constants/constants';


@Component({
  selector: 'app-alias-group-members',
  
  templateUrl: './alias-group-members.component.html',
  styleUrls: ['./alias-group-members.component.scss']
})
export class AliasGroupMembersComponent implements OnInit, OnDestroy {

  constructor(public viewContainerRef: ViewContainerRef, 
    private dataService:DataServiceServiceProfile,
    private loadingSpinner:LoadingSpinnerService,
    private translationService:TranslationService
    ) { 

  }
  isModalOpen:boolean=false;
  subscriptions: Subscription[] = [];
  members:any
  groupName:any
  translationData:any
  profileName:any
  public enableEditProfile: boolean = false;
  profileDetails:any;
  isIconInfoShown : boolean = false;

  ngOnInit(): void {
    this.groupName=localStorage.getItem("aliasGroupName")
    this.profileName=localStorage.getItem("profileName")
    this.subscriptions.push(this.dataService.getMemberList(this.groupName).subscribe((response:any)=>{
      let data = response.body
      if(data){
      this.members=data.members
      this.profileDetails=data.profile
      this.loadingSpinner.hide()
      }
    }))
    this.subscriptions.push(this.translationService
      .getTranslationDataAsObservable()
      .subscribe((translationData: any) => {
        this.translationData = translationData;
        this.enableEditProfile = this.translationService.featureFlags?.profile.enableEditProfile;
      }));
  }
  isMenuClicked:boolean=false;
  showIndex:any;
  dp:any='assets/images/profile.svg'
  linkout:any='assets/images/alias/resize.svg'
  info:any='assets/images/alias/information.svg'
  componentName:string='alias-group-members'
  isCloseModal:boolean=false
  headerTitle:string='Add Member'
  closeButtonTitle:string=AppConstants.COMMON_BUTTON_LABELS.CANCEL
  saveButtonTitle:string='Invite'
  editProfileClicked:boolean=false
  public buttonNames: any = AppConstants.COMMON_BUTTON_LABELS;
  
menuClicked(i:any){
  this.isMenuClicked=!this.isMenuClicked
  this.showIndex=i
}
closeDialog(){
  this.isMenuClicked=false
}
addMember(){
  //  method 'addMember' is empty
}
editProfile(){
 this.editProfileClicked=true
}
cancelClicked(event:any){
if(event){
  this.editProfileClicked=false
}
}
ngOnDestroy(): void {
  this.subscriptions.forEach((subscription) => {
    subscription.unsubscribe();
  });
}


}
