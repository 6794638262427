import { Component, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AppConstants } from 'src/app/shared/constants/constants';

@Component({
  selector: 'invite-member-form',
  templateUrl: './invite-member-form.component.html',
  styleUrls: ['./invite-member-form.component.scss']
})
export class InviteMemberFormComponent {

  headerTitle:string='Add Member'
  closeButtonTitle:string=AppConstants.COMMON_BUTTON_LABELS.CLOSE
  saveButtonTitle:string='Invite'
  isCloseModal:boolean=true
  showModal:boolean=false
  formSubmitted:boolean=false
  type:string='mediumPopup'
  constructor(
     public fb:UntypedFormBuilder 
    ) { }

  @ViewChild('showModalContent') showModalContent:TemplateRef<any> |any
 
  addMemberForm=this.fb.group({
    email:['',Validators.required]
  })
  

 

  closeModal(){
     this.isCloseModal=true
  }
  saveModal(){
    //logics needs to be added
  }

  onSubmit(){
  this.formSubmitted=true
  console.log('form submitted')
  }

}
